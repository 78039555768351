<template>
  <section class="section section-news">
    <div class="container">
      <h1 class="section__title title">Новости</h1>
      <news :news="getNews" />
    </div>
  </section>
</template>


<script>
import News from '@/components/news/News.vue';
import { mapGetters } from 'vuex'
export default {
  components: { News },
  computed: {
    ...mapGetters(['getNews'])
  }
};
</script>