<template>
    <div class="news-wrap"  :class="{ 'main-page': isMain }">
        <ul class="news__list">
            <li 
                class="news__item"
                v-for="news in news"
                :key="news.id"
                :style = "{'background-image': 'linear-gradient(180.02deg, rgba(171, 71, 196, 0.49) 0.02%, rgba(187, 104, 207, 0.49) 31.87%, rgba(244, 224, 249, 0) 145.61%), url(' + `${news.photos[0].url}` + ')'}"
            >
                <div class="news__title">{{ news.title }}</div>
                <router-link class="link" :to="{ name: 'NewsSingle', params: { id: news.id } }" >Перейти</router-link>
            </li>
        </ul>
        <div class="news__more-wrap" v-if="isMain">
            <router-link 
              :to="{ name:'News' }" 
              class="news-more__link line">
              Больше новостей
            </router-link>
        </div>
    </div>
</template>

<script>
    export default {
        name: "News",
        props: ["news", "isMain"],
    }
</script>